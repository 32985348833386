<template>
  <div id="app">
    <keep-alive :include="[]">
      <router-view/>
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: 'App',
  mounted () {
    // ios 禁止缩放
    document.documentElement.addEventListener(
      'touchstart',
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      },
      false
    )

    var lastTouchEnd = 0
    document.documentElement.addEventListener(
      'touchend',
      function (event) {
        var now = Date.now()
        if (now - lastTouchEnd <= 300) {
          event.preventDefault()
        }
        lastTouchEnd = now
      },
      false
    )

    document.addEventListener('gesturestart', function (event) {
      event.preventDefault()
    })
  }
}
</script>
<style lang="less">

</style>
