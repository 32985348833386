import Vue from 'vue'
import VueRouter from 'vue-router'
import { Toast } from 'vant'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' }, // 重定向到首页
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "bundle" */ '../views/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "bundle" */ '../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bundle" */ '../views/register.vue')
  },
  {
    path: '/shoppingMall',
    name: 'shoppingMall',
    component: () => import(/* webpackChunkName: "bundle" */ '../views/shoppingMall.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import(/* webpackChunkName: "bundle" */ '../views/orderDetail.vue')
  },
  {
    path: '/huipingList',
    name: 'huipingList',
    component: () => import(/* webpackChunkName: "bundle" */ '../views/huipingList.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import(/* webpackChunkName: "bundle" */ '../views/mine.vue')
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import(/* webpackChunkName: "bundle" */ '../views/orderList.vue')
  },
  {
    path: '/addressList',
    name: 'addressList',
    component: () => import(/* webpackChunkName: "bundle" */ '../views/addressList.vue')
  },
  {
    path: '/addressListAdd',
    name: 'addressListAdd',
    component: () => import(/* webpackChunkName: "bundle" */ '../views/addressListAdd.vue')
  },
  {
    path: '/peisong',
    name: 'peisong',
    component: () => import(/* webpackChunkName: "bundle" */ '../views/peisong.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
console.log(process.env.BASE_URL)
const whiteList = ['/login', '/index', '/register', '/shoppingMall'] // 白名单
router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}
  if (!userInfo || !userInfo.user_id) {
    // 判断是否在白名单中
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      Toast.fail('请先登录')
      return next({ path: '/login' })
    }
  } else {
    // 配送员只能进入配送页面
    if (userInfo.role_id === 5 && to.path !== '/peisong') {
      next({ path: '/peisong' })
    } else {
      next()
    }
  }
})

// 解决路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
