import Vue from 'vue'
// 按需引入
import {
  Button,
  Form,
  Field,
  Area,
  Popup,
  Sticky,
  List,
  Tab,
  Tabs,
  Card,
  Stepper,
  SubmitBar,
  Badge,
  Icon,
  Cell,
  NoticeBar,
  AddressList,
  AddressEdit,
  Search,
  ActionSheet,
  Toast
} from 'vant'
Vue.use(Button)
Vue.use(Form)
Vue.use(Field)
Vue.use(Area)
Vue.use(Popup)
Vue.use(Sticky)
Vue.use(List)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Card)
Vue.use(Stepper)
Vue.use(SubmitBar)
Vue.use(Badge)
Vue.use(Icon)
Vue.use(Cell)
Vue.use(NoticeBar)
Vue.use(AddressList)
Vue.use(AddressEdit)
Vue.use(Search)
Vue.use(ActionSheet)
Vue.use(Toast)
