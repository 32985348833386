import axios from 'axios'
import { Toast } from 'vant'
const instance = axios.create({
  baseURL: '/api'
})
instance.interceptors.request.use(function (config) {
  Toast.loading({
    message: '加载中...',
    forbidClick: true
  })
  return config
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  Toast.clear()
  if (response.data.code !== 200) {
    Toast.fail(response.data.message || '系统异常')
    return
  }
  return response.data
}, function (error) {
  Toast.clear()
  return Promise.reject(error)
})

export default instance
