import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入组件文件
import '@/vant'
import '@/assets/css/reset.less'
import '@/assets/css/common.less'
import VConsole from 'vconsole'
import wx from 'weixin-js-sdk'
import http from '@/utils/request'
// 手机端调试
if (process.env.NODE_ENV === 'development') {
  const vConsole = new VConsole()
  Vue.use(vConsole)
}

Vue.prototype.$http = http

// 挂在vue的原型上方便使用
Vue.prototype.$wx = wx

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
